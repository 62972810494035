import React from "react";
import { Link } from "gatsby";
import Layout from "../../components/layout/layout";

import SEO from "../../components/seo";

const posts = [
  {
    title: "Lunar New Year 2022 Celebration with Hospital",
    link: "/events/lunar-new-year-celebration-with-ren-ci-hospital",
    description:
      "Singapore Women's Association in collaboration with Ren Ci Hospital brought much joy to the residents with our annual Lunar New Year activities. ",
    imageUrl:
      "https://res.cloudinary.com/dkm14ge3g/image/upload/v1645186273/eventPhoto/aie6rjki4fvoh7nnbdvt.webp",
  },
  {
    title: "National Day Celebration @ Ren Ci Hospital 2020",
    link: "/events/national-day-celebration-@-ren-ci-hospital-2020",
    description:
      "We understand that social distancing has driven many people to delve into the darker realms of our minds. It is tough for regular folks with mobility and ability. This mental social isolation is further amplified for residents of hospices, homes and hospitals when visitations from loved one were restricted for months. SWA brought some cheer and joy for the residents at Ren Ci in our small ways through our activities.",
    imageUrl:
      "https://res.cloudinary.com/dkm14ge3g/image/upload/v1634780833/eventPhoto/vjssvyuaxa1o6bh859fu.png",
  },
  {
    title: "NDP Cheers 2021",
    link: "/events/ndp-cheers-2021",
    description:
      "Our Pioneer generation grew up during the tumultuous years of the 1930s and early 1960s. They steered the labour force in turbulent times, building a sturdy foundation.The covid situation has greatly reduced the quality of daily lives.",
    imageUrl:
      "https://res.cloudinary.com/dkm14ge3g/image/upload/v1634722809/eventPhoto/o7for3iwez1zetfdf9hw.png",
  },
];

const renci = () => {
  return (
    <>
      <div className="absolute w-full h-32 z-n10 bg-gradient-to-r from-swa-1 to-swa-5"></div>
      <Layout>
        <SEO
          title="Ren Ci Hospital"
          description="SWA has adopted Ren Ci Hospital for the outreach programme to seniors who stay at the hospital. "
        />
        <section className="max-w-4xl px-2 mx-auto sm:w-4/5">
          <div className="relative py-16 overflow-hidden bg-white">
            <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
              <div
                className="relative h-full mx-auto text-lg max-w-prose"
                aria-hidden="true"
              >
                <svg
                  className="absolute transform translate-x-32 top-12 left-full"
                  width={404}
                  height={384}
                  fill="none"
                  viewBox="0 0 404 384"
                >
                  <defs>
                    <pattern
                      id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className="text-gray-200"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width={404}
                    height={384}
                    fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
                  />
                </svg>
                <svg
                  className="absolute transform -translate-x-32 -translate-y-1/2 top-1/2 right-full"
                  width={404}
                  height={384}
                  fill="none"
                  viewBox="0 0 404 384"
                >
                  <defs>
                    <pattern
                      id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className="text-gray-200"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width={404}
                    height={384}
                    fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
                  />
                </svg>
                <svg
                  className="absolute transform translate-x-32 bottom-12 left-full"
                  width={404}
                  height={384}
                  fill="none"
                  viewBox="0 0 404 384"
                >
                  <defs>
                    <pattern
                      id="d3eb07ae-5182-43e6-857d-35c643af9034"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className="text-gray-200"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width={404}
                    height={384}
                    fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
                  />
                </svg>
              </div>
            </div>
            <div className="relative px-4 sm:px-6 lg:px-8">
              <div className="mx-auto text-lg max-w-prose">
                <h1>
                  {/* <span className="block text-base font-semibold tracking-wide text-center text-indigo-600 uppercase">
                    Introducing
                  </span> */}
                  <span className="block mt-2 text-3xl font-extrabold leading-8 tracking-tight text-center text-swa-1 sm:text-4xl">
                    Ren Ci Hospital
                  </span>
                </h1>
                <div className="mt-8 text-xl leading-8 text-gray-500">
                  SWA has adopted Ren Ci Hospital for the outreach programme to
                  seniors who stay at the hospital. We foster collaborative
                  partnerships to enhance the well-being and health of the
                  people we serve. The objective is to partner with Ren Ci to
                  provide inclusive healthcare for quality and meaningful
                  living.
                  <div className="my-2 border border-swa-4 aspect-w-16 aspect-h-9">
                    <iframe
                      src={`https://www.youtube.com/embed/XbJLPuZJjis`}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      title="Embedded youtube"
                      className="w-full p-2"
                    />
                  </div>
                  2020 - 2021 has been extremely challenging years for everyone.
                  Covid-19 has left its footprint on our global map and it is
                  still leaving its mark as we speak. Its massive destructive
                  impact came like an unforeseen typhoon crashing upon all of
                  us. Economies, jobs, lifestyle, daily habits, families,
                  health, education and mental well-being are all impacted. It
                  came as a universal leveller and no one is spared.
                  <br />
                  <br />
                  Singapore Women Association (SWA) had to learn fast and hard
                  to conduct meetings via digital platforms and arrange
                  activities through digital tools. Our annual activities with
                  various homes were disrupted by this global disaster however,
                  we persevered and discover new ways to bring that lift of the
                  lips and twinkle in the eyes of the participants of our
                  evergreen activities.
                </div>
                <div className="my-2 border border-swa-4 aspect-w-16 aspect-h-9">
                  <iframe
                    src={`https://www.youtube.com/embed/eDcJl4ejajE`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube"
                    className="w-full p-2"
                  />
                </div>
              </div>
            </div>
            <div className="relative px-4 pt-16 pb-20 bg-gray-50 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
              <div className="absolute inset-0">
                <div className="bg-white h-1/3 sm:h-2/3" />
              </div>
              <div className="relative mx-auto max-w-7xl">
                <div className="text-center">
                  <h2 className="text-3xl font-extrabold tracking-tight text-swa-1 sm:text-4xl">
                    Past events with Ren Ci Hospital
                  </h2>
                  {/* <p className="max-w-2xl mx-auto mt-3 text-xl text-gray-500 sm:mt-4">
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Ipsa libero labore natus atque, ducimus sed.
                  </p> */}
                </div>
                <div className="grid max-w-lg gap-5 mx-auto mt-12 lg:grid-cols-3 lg:max-w-none">
                  {posts.map(post => (
                    <div
                      key={post.title}
                      className="flex flex-col overflow-hidden rounded-lg shadow-lg"
                    >
                      <div className="flex-shrink-0">
                        <img
                          className="object-cover w-full h-48"
                          src={post.imageUrl}
                          alt=""
                        />
                      </div>
                      <div className="flex flex-col justify-between flex-1 p-6 bg-white">
                        <div className="flex-1">
                          {/* <p className="text-sm font-medium text-indigo-600">
                            <a
                              href={post.category.href}
                              className="hover:underline"
                            >
                              {post.category.name}
                            </a>
                          </p> */}
                          <Link to={post.link} className="block mt-2">
                            <p className="text-xl font-semibold text-swa-1">
                              {post.title}
                            </p>
                            <p className="mt-3 text-base text-gray-600 line-clamp-4">
                              {post.description}
                            </p>
                          </Link>
                        </div>
                        {/* <div className="flex items-center mt-6">
                          <div className="flex-shrink-0">
                            <a href={post.author.href}>
                              <span className="sr-only">
                                {post.author.name}
                              </span>
                              <img
                                className="w-10 h-10 rounded-full"
                                src={post.author.imageUrl}
                                alt=""
                              />
                            </a>
                          </div>
                          <div className="ml-3">
                            <p className="text-sm font-medium text-gray-900">
                              <a
                                href={post.author.href}
                                className="hover:underline"
                              >
                                {post.author.name}
                              </a>
                            </p>
                            <div className="flex space-x-1 text-sm text-gray-500">
                              <time dateTime={post.datetime}>{post.date}</time>
                              <span aria-hidden="true">&middot;</span>
                              <span>{post.readingTime} read</span>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default renci;
